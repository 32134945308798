/**
 *
 * Helper file with miscellaneous functions to fix support or to help speed up development.
 *
 * @author Linus Karlsson
 * @website webqon.com
 *
 * @license
 *
 * you can redistribute it and/or modify it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
 * See the GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along this software. If not, see <https://www.gnu.org/licenses/>.
 *
 **/

/**
 *
 * Prototype function return bool if string end characters matches with parameter string.
 *
 * @property endsWith
 * @type String
 * @param {String}
 * @return {Boolean}
 *
 **/

if (typeof String.prototype.endsWith !== 'function') {
	String.prototype.endsWith = (suffix) => { // eslint-disable-line
		return this.indexOf(suffix, this.length - suffix.length) !== -1
	}
}

/**
 *
 * Prototype function return bool if string first set of characters matches with parameter string.
 *
 * @property startsWith
 * @type String
 * @param {String}
 * @return {Boolean}
 *
 **/

if (typeof String.prototype.startsWith !== 'function') {
	String.prototype.startsWith = (suffix) => { // eslint-disable-line
		return this.lastIndexOf(suffix, 0) === 0
	}
}

/**
 *
 * Prototype function that returns the first Element with specified className.
 *
 * @property getElementByClass
 * @type Element
 * @param {String} Some Classname
 * @return {Element}
 *
 **/

if (typeof Element.prototype.getElementByClass !== 'function') {
	Element.prototype.getElementByClass = function (s) {
		return this.getElementsByClassName(s)[0]
	}
}

/**
 *
 * Prototype function that returns the closest ancestor matching parameter.
 * (this adds support for IE9+)
 *
 * @property closest
 * @type Element
 * @param {Selectors} DOMString,
 * @return {Element}
 *
 **/

if (typeof Element.prototype.matches !== 'function') {
	Element.prototype.matches = Element.prototype.msMatchesSelector ||
								Element.prototype.webkitMatchesSelector
}

if (typeof Element.prototype.closest !== 'function') {
	Element.prototype.closest = function (s) {
		var el = this
		do {
			if (el.matches(s)) {
				return el
			}
			el = el.parentElement || el.parentNode
		} while (el !== null && el.nodeType === 1)
	}
}

/**
 *
 * Prototype function that set the data-hidden attrebute on element for hiding or showing that element using CSS.
 *
 * @property classToggle
 * @type Element
 * @param {String} Some Classname.
 * @param {Boolean} [true | false] (Optional) If undefined = toggle.
 *
 **/

if (typeof Element.prototype.classToggle !== 'function') {
	Element.prototype.classToggle = function (className, bool) {
		if (typeof bool !== 'undefined') {
			if (bool && !this.classList.contains(className)) {
				this.classList.add(className)
			} else if (!bool && this.classList.contains(className)) {
				this.classList.remove(className)
			}
		} else {
			if (!this.classList.contains(className)) {
				this.classList.add(className)
			} else if (this.classList.contains(className)) {
				this.classList.remove(className)
			}
		}
	}
}

/**
 *
 * Prototype function that set the data-hidden attrebute on element for hiding or showing that element using CSS.
 *
 * @property hiddenToggle
 * @type Element
 * @param {Boolean} [true | false] (Optional) If undefined = toggle, If !attribute = set attribute true.
 *
 **/

if (typeof Element.prototype.hiddenToggle !== 'function') {
	Element.prototype.hiddenToggle = function (bool) {
		if (typeof bool !== 'undefined') {
			this.setAttribute('data-hidden', bool)
		} else {
			if (this.getAttribute('data-hidden') !== 'false') {
				this.setAttribute('data-hidden', false)
			} else {
				this.setAttribute('data-hidden', true)
			}
		}
	}
}

/**
 *
 * Prototype function that set the data-hidden attrebute on element for hiding or showing that element using CSS.
 *
 * @property hiddenToggle
 * @type Element
 * @param {Boolean} [true | false] (Optional) If undefined = toggle, If !attribute = set attribute true.
 *
 **/

if (typeof Element.prototype.isDescendant !== 'function') {
	Element.prototype.isDescendant = function (parent) {
		var node = this.parentNode
		while (node != null) {
			if (node === parent) {
				return true
			}
			node = node.parentNode
		}
		return false
	}
}

/**
 *
 * Prototype function returns true if an array have no two matching values.
 *
 * @property isValuesUnique
 * @type Array
 * @return {Boolean} Returns true on success.
 *
 **/

if (typeof Array.prototype.isValuesUnique !== 'function') {
	Array.prototype.isValuesUnique = () => { // eslint-disable-line
		for (let i = 0; i < this.array.length; i++) {
			const a = this.array.filter((b) => b === this.array[i])
			if (a.length > 1) {
				return false
			}
		}
		return true
	}
}

(function () {
	if (typeof window.CustomEvent === 'function') return false

	function CustomEvent (event, params) {
		params = params || { bubbles: false, cancelable: false, detail: null }
		var evt = document.createEvent('CustomEvent')
		evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
		return evt
	}

	window.CustomEvent = CustomEvent
})()
