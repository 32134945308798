import messages from './messages';

const form = document.getElementById('contact');

if (form) {
	const submitMessage = (message, email, name, subject, token, callback) => {
		var xhttp = new XMLHttpRequest();

		var parms = 'message=' + message + '&email=' + email + '&name=' + name + '&subject=' + subject + '&token=' + token
		
		xhttp.open("POST", "/api/contact-form", true);
		xhttp.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
		
		xhttp.onreadystatechange = () => {

			if (xhttp.readyState == 4 && xhttp.status === 200) {
				callback(xhttp.responseText);
				console.log(xhttp.responseText);
			} else {
				messages.message('error', 'Something is wrong! Try again later.')
			}
		}

		xhttp.send(parms);	
	}

	form.addEventListener('click', (event) => {
		if (document.getElementById('form-submit') === event.target) {

			grecaptcha.ready( () => {
				grecaptcha.execute('6Ld0kpkcAAAAAGGy6mxfMsrmJOV_wOz6AIy_saF0', {action: 'submit'}).then( (token) => {

					var message = document.getElementById('form-message')
					var subject = document.getElementById('form-subject')
					var email 	= document.getElementById('form-email')
					var name 	= document.getElementById('form-name')

					subject.classToggle('input-req', false)
					email.classToggle('input-req', false)
					name.classToggle('input-req', false)
					
					if (message.value 	&&
						email.value 	&&
						name.value 		&&
						subject.value 	&&
						token) {

						submitMessage(message.value, email.value, name.value, subject.value, token, (event) => {
							if (event === 'sent') {

								// Hide
								form.classToggle('hide-form')

								// reset the inputs
								message.value = ''
								subject.value = ''
								email.value = ''
								name.value = ''

								// send message
								messages.message('message', 'Thanks for your message!') 
							} else if (event === 'invalid email') {
								email.classToggle('input-req', true)
							} else if (event === 'invalid name') {
								name.classToggle('input-req', true)
							} else if (event === 'invalid subject') {
								subject.classToggle('input-req', true)
							} else if (event === 'invalid token') {
								// Hide
								form.classToggle('hide-form')
							} else {
								messages.message('error', 'Could not send it!?')
							}
						})
					} else {
						messages.message('error', 'fyll alla fält.')
					}
				});
			});
		}
	})
}