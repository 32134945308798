const loadElm = document.getElementById('page-loader');

const urlEvent = new Event('urlChange');

function getPage(url) {
	loadElm.style.display = 'block';

	setTimeout(() => {
		loadElm.classList.add('loading');
	}, 10);

	setTimeout(() => {
	
		var xhttp = new XMLHttpRequest();
		
		xhttp.onreadystatechange = function() {
			if (this.readyState == 4 && this.status == 200) {
	
				const data = JSON.parse(this.response);

				setPage(data);

				// Push new page
				window.history.pushState(data, document.title, url)
			
				setTimeout(() => {
					loadElm.style.display = 'none';
				}, 1000);
			}

			// disable loading
			loadElm.classList.remove('loading');
		};

		xhttp.open("GET", "/api/page?page=" + url, true);
		xhttp.send();

	}, 800);
	
}

function setPage(data) {
	document.getElementById('content-container').innerHTML = data.template;

	if (data.data.title) {
		document.title = data.data.title;
	} else {
		document.title = 'Däckladan';
	}

	document.dispatchEvent(urlEvent);
}


function init() { 
	const links = document.getElementsByTagName('A');
	
	if (links.length > 0) {
		Array.from(links).forEach( (link) => {
			if (link.getAttribute('href').startsWith('/')) {
				link.addEventListener('click', (event) => {				
					event.preventDefault(); 
					getPage(event.target.getAttribute('href'));
				})	
			}
		})
	}
}

window.onpopstate = function(event){
	setPage(event.state)
}

//init();
