/***************************
*
*	Input & label harmonics 
*
****************************/

const inputs = Array.from(document.getElementsByTagName('input'))

if (inputs.length > 0) {
	inputs.forEach((input) => {
		if (input.previousElementSibling.tagName === 'LABEL') {
			input.parentElement.classToggle('have-title', true)

			// If content 
			input.addEventListener('input', (event) => {
				const et = event.target

				if (et.value !== '') {
					et.parentElement.classToggle('content-exist', true)
				} else {
					et.parentElement.classToggle('content-exist', false)
				}
			})
	
			// if focus in
			input.addEventListener('focusin', (event) => {
				event.target.parentElement.classToggle('focus-exist', true)
			});

			// if focus out
			input.addEventListener('focusout', (event) => {
				event.target.parentElement.classToggle('focus-exist', false)
			});
		}
	})
}