exports.message = (type, message) => {
	const wrapper = document.getElementById('message-wrapper')
	var messageElm = document.createElement('DIV')
	
	messageElm.innerText = message;

	if (type === 'error') {
		messageElm.classToggle('error-msg', true)
	} else if (type === 'message') {
		messageElm.classToggle('message-msg', true)
	}

	const idNumber = Math.floor(Math.random() * Math.floor(99999))

	messageElm.id = 'msg-' + idNumber;

	// do it
	wrapper.appendChild(messageElm)

	setTimeout(() => {
		const elm = document.getElementById('msg-' + idNumber)
		elm.style.display = 'none'
	}, 6000);
}