const menu = document.getElementById('main-menu')

const activeLink = () => {
    if (menu) {
        const pathname = window.location.pathname
        
        Array.from(menu.children).forEach(element => {
            const link = element.firstElementChild
            
            link.classToggle('active', false)
            
            if (link.getAttribute("href") === pathname) {
                link.classToggle('active', true)
            }
        });
    }
}

document.addEventListener('urlChange', function (e) { 
    activeLink()
}, false);

activeLink()


// Mobile view
const menuButton = document.getElementById('main-menu-button')

if (menuButton && menuButton !== undefined) {
    document.addEventListener('click', (event) => {
        const target = event.target
        if (target === menuButton) {
            menu.parentElement.classToggle('open-menu')
        } else if (target !== menu.parentElement) {
            menu.parentElement.classToggle('open-menu', false)
        }
    })

}